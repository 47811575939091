import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import { groupBy } from '@/utils/'
import axios from 'axios'

Vue.use(Vuex)
const adminfb = require("firebase-admin");
adminfb.initializeApp();
const VUE_APP_AIRTABLE_BASE = 'appJolmCkHQJR6T4V';
const VUE_APP_AIRTABLE_API_KEY='key9kIa7VOO6tXq0d';
const TABLE_NAME = 'r318';        
const store = new Vuex.Store({
  state: {
    userProfile: {},
    posts: [],
    currentForm: null,
    currentFormAnswers: null,
    post: null,
    forms: [],
    settings: null,
    users: null,
    reportItems:null
  },
  getters: {
    isAdmin: state => state.userProfile.isAdmin,
    activeWeek: state => {
      if (state.settings && state.settings.activeWeek) {
        return state.settings.activeWeek
      }
    },
    activeForms: state => {
      if (state.settings && state.settings.activeWeek) {
        return state.forms.filter(form => form.week == state.settings.activeWeek)
      }
    },
    weeks: state => groupBy(state.forms, 'week')
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setPosts(state, val) {
      state.posts = val
    },
    setForms(state, val) {
      state.forms = val
    },
    setCurrentForm(state, val) {
      state.currentForm = val
    },
    setPost(state, val) {
      state.post = val
    },
    setCurrentFormAnswers(state, val) {
      state.currentFormAnswers = val
    },
    setLoading(state, val) {
      state.loading = val
    },
    setSettings(state, val) {
      state.settings = val
    },
    setActiveWeek(state, val) {
      state.settings.activeWeek = val
    },
    setUsers(state, val) {
      state.users = val
    },
    setReportItems(state, val) {
      state.reportItems = val
    }
  },
  actions: {
    loading({ commit }, val) {
      commit('setLoading', val)
    },
    async login({ dispatch }, form) {
      try {
        dispatch('loading', true)
        // sign user in
        const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

        // fetch user profile and set in state
        dispatch('fetchUserProfile', user)
      } finally {
        dispatch('loading', false)
      }
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        name: form.name,
        lastName: form.lastName
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async createUser(_, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        name: form.name,
        lastName: form.lastName,
        isAdmin: form.isAdmin
      })
    },
    async deleteUser(_, uId) {
      //console.log(firebaseConfig);
    /*  await adminfb.deleteUser(uId).then(() => {
        fb.usersCollection.doc(uId).delete();
          console.log('Deleted ' + uId)
      
      });*/
      console.log(uId);
      //let db=adminfb.firestore();
      //await db.collection('users').doc(uId).delete();
      fb.usersCollection.doc(uId).delete();
      console.log('Deleted ' + uId);
      await adminfb.auth().deleteUser(uId);
      console.log('Deleted auth ' + uId);
     /* const res = await adminfb.auth().listUsers(1000);
      console.log(res);
      const users = res.users.filter(u => u.uid==uId);
      console.log(users);
      users.forEach(x => {
        adminfb
          .auth()
          .deleteUser(x.uid)
          .then(() => {
            console.log(`Successfully deleted user ${x.email}`);
          })
          .catch(error => {
            console.log('Error deleting user:', error);
          });
      });*/

    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      // redirect to login view
      router.push('/login')
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // set user profile in state
      commit('setUserProfile', userProfile.data())

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async fetchForms({ commit }) {
      fb.formsCollection.orderBy('date', 'asc').onSnapshot(snapshot => {
        let formsArray = []

        snapshot.forEach(doc => {
          let form = doc.data()
          form.id = doc.id

          formsArray.push(form)
        })

        commit('setForms', formsArray)
      })
    },
    async loadItemsReport({ commit }, s){
      return new Promise((resolve, reject) => {
       axios.get(`https://api.airtable.com/v0/${VUE_APP_AIRTABLE_BASE}/${TABLE_NAME}?filterByFormula=or(SEARCH(%22${s}%22%2C+{Name}),SEARCH(%22${s}%22%2C+{Form}),SEARCH(%22${s}%22%2C+{UserId}))`,
            { headers: { Authorization: "Bearer " + VUE_APP_AIRTABLE_API_KEY }})
        .then((response) => {
            this.items = [];
            // load the API response into items for datatable
            this.items = response.data.records.map((item)=>{
                return {
                    id: item.id,
                    Name:item.fields.Name,
                    Form:item.fields.Form,
                    Date:item.fields.Date
                }
            })
            console.log(this.items);
            commit('setReportItems', this.items)
            resolve('Success')
        }).catch((error) => {
            console.log(error)
            reject(error)
        })})
    },
    async fetchForm({ commit }, formId) {
      // fetch form
      const form = await fb.formsCollection.doc(formId).get()
      // set current form
      commit('setCurrentForm', form.data())
    },
    async createPost({ state }, post) {
      // create post in firebase
      await fb.postsCollection.add({
        createdOn: new Date(),
        content: post.content,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name,
        comments: 0,
        likes: 0
      })
    },
    async createForm({ state }, form) {
      // create form in firebase
      try {
        await fb.formsCollection.add({
          ...form,
          createdBy: state.userProfile.name,
          createdAt: new Date()
        })
      } finally {
        console.log('FORM CREATED')
      }
    },
    async updateForm(_, form) {
      // update form
      try {
        const res = await fb.formsCollection.doc(form.id).update(form)
        return res
      } finally {
        console.log('FORM UPDATED')
      }
    },
    async deleteForm(_, id) {
      // update form
      try {
        const res = await fb.formsCollection.doc(id).delete()
        return res
      } finally {
        console.log('FORM DELETED')
      }
    },
    async likePost (_, post) {
      const userId = fb.auth.currentUser.uid
      const docId = `${userId}_${post.id}`

      // check if user has liked post
      const doc = await fb.likesCollection.doc(docId).get()
      if (doc.exists) { return }

      // create post
      await fb.likesCollection.doc(docId).set({
        postId: post.id,
        userId: userId
      })

      // update post likes count
      fb.postsCollection.doc(post.id).update({
        likes: post.likesCount + 1
      })
    },
    async userPost({ commit }, data) {
      const userId = fb.auth.currentUser.uid

      // const userRef = await fb.usersCollection.doc(userId)
      const postData = {
        answers: data.fields,
        userId,
        formId: data.postId
      }


      const userPost = await fb.postsCollection.doc(`${data.postId}_${userId}`).set(postData)

      // const answers = JSON.parse(data.fields)
      // answers.map(answer => {
      //   if (answer) {
      //     fb.postsCollection.doc(`${data.postId}_${userId}`).collection('answers').doc(`${data.postId}_${userId}`).set(answer)
      //   }
      // })

      commit('setPost', userPost)
    },
    async notifyPost({commit}, data) {
      const userId = fb.auth.currentUser.uid
      
      // const userRef = await fb.usersCollection.doc(userId)
      const postData = {
        answers: [],
        createdAt: new Date(),
        userId,
        formId: data.postId
      }
      //console.log(postData);
      // Si no existe, enviar email notificando que entró por primera vez
      let document = await fb.postsCollection.doc(`${data.postId}_${userId}`).get();
      if (document && !document.exists) {
        const userPost = await fb.postsCollection.doc(`${data.postId}_${userId}`).set(postData)
        commit('setPost', userPost)
        // Email + airtable
        const airtableBase = axios.create({
          baseURL: `https://api.airtable.com/v0/${VUE_APP_AIRTABLE_BASE}/`,
          headers: {
            Authorization: 'Bearer ' + VUE_APP_AIRTABLE_API_KEY,
            'Content-Type': 'application/json'
          }
        })
        console.log(fb.auth.currentUser);
        const data2 = {
          fields: {
            UserId:userId,
            Name:fb.auth.currentUser.email,
            FormId:data.postId, 
            Form: data.title + '. Semana ' + data.descrip,
            Date: new Date()
          }
        }
        const response = await airtableBase.post(`${TABLE_NAME}`, data2)
        console.log(response);
     }
    },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      await fb.usersCollection.doc(userId).update({
        name: user.name,
        lastName: user.lastName
      })

      dispatch('fetchUserProfile', { uid: userId })

      // update all posts by user
      const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      postDocs.forEach(doc => {
        fb.postsCollection.doc(doc.id).update({
          userName: user.name
        })
      })

      // update all comments by user
      const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
      commentDocs.forEach(doc => {
        fb.commentsCollection.doc(doc.id).update({
          userName: user.name
        })
      })
    },
    async fetchAnswers({ commit }, formId) {
      const userId = fb.auth.currentUser.uid
      const answers = await fb.postsCollection.doc(`${formId}_${userId}`).get()

      commit('setCurrentFormAnswers', answers.data())
    },
    async getSettings({ commit }) {
      const settings = await fb.settingsCollection.doc('prod').get()
      commit('setSettings', settings.data())
    },
    setActiveWeek({ commit }, val) {
      commit('setActiveWeek', val)
    },
    async updateSettingsVerseUrl(_, val) {
      try {
        fb.settingsCollection.doc('prod').update({
          verseUrl: val
        })
      } catch(e) {
        console.log('VERSE URL UPDATE ERROR', e)
      }
    },
    async updateSettingsActiveWeek(_, val) {
      try {
        fb.settingsCollection.doc('prod').update({
          activeWeek: val
        })
      } catch(e) {
        console.log('ACTIVE WEEK UPDATE ERROR', e)
      }
    },
    async fetchPosts({ commit }) {
      fb.postsCollection.onSnapshot(snapshot => {
        let postsArray = []

        snapshot.forEach(doc => {
          let post = doc.data()
          post.id = doc.id

          postsArray.push(post)
        })

        commit('setPosts', postsArray)
      })
    },
    async fetchUsers({ commit }) {
     // console.log(adminfb.auth);
      //console.log(adminfb.auth());
      console.log(fb.auth);
      fb.usersCollection.orderBy("name", "asc").onSnapshot(snapshot => {
        let usersArray = []

        snapshot.forEach(doc => {
          let user = doc.data()
          user.id = doc.id;
          usersArray.push(user);
         
          
          
          
        })

        commit('setUsers', usersArray)
      })
    }
  }
})

export default store
