import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import VueYouTubeEmbed from 'vue-youtube-embed'
import vueDebounce from 'vue-debounce'
import Notifications from 'vue-notification'
import './assets/scss/app.scss'

Vue.use(Notifications)
Vue.use(VueYouTubeEmbed)
Vue.use(vueDebounce)

Vue.config.productionTip = false

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
    store.dispatch('getSettings')
  }
})
