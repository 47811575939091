<template>
  <div>
    <h1 v-if="user">Respuestas de {{ user.name }}</h1>
    <div class="postItem" @click="() => {}" :key="post.id" v-for="post in userPosts">
      <div class="devotionalItem">
        <div class="devotionalItem__title">{{ post.form.title }}</div>
        <div class="devotionalItem__day">Día {{ post.form.day }}</div>
        <div class="devotionalItem__week">Semana {{ post.form.week }}</div>
      </div>
      <div class="answerItem" @click="() => {}" :key="i" v-for="(answer, i) in post.answers">
        <div v-if="answer && answer.question" class="answerItem__question"><b>{{ answer.question }}</b></div>
        <div v-if="answer && answer.answer" class="answerItem__answer">- {{ answer.answer }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as fb from '../firebase'

export default {
  data() {
    return {
      user: null,
      userPosts: []
    }
  },
  computed: {
    ...mapState(['forms'])
  },
  methods: {
    async getUserPosts() {
      const ref = this
      const userPostsSnap = await fb.postsCollection.where('userId', '==', this.$route.params.id).get()
      userPostsSnap.forEach(function(doc) {
          const post = doc.data()
          const postForm = ref.forms.find(form => form.id === post.formId)

          const postComplete = {
            form: postForm,
            answers: JSON.parse(post.answers)
          }

          ref.userPosts.push(postComplete)
      });
    },
    async getUser() {
      const user = await fb.usersCollection.doc(this.$route.params.id).get()
      this.user = user.data()
    }
  },
  mounted() {
    this.getUser()

    if (this.forms && this.forms.length > 0) {
      this.getUserPosts()
    }
  }
}
</script>

<style lang="scss" scoped>
.devotionalItem {
  background-color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid rgba(#cccccc, 0.3);
  display: flex;
  justify-content: space-between;

  &__title {
    margin-right: auto;
  }

  &__day {
    padding: 0 1rem;
  }

  &:hover {
    background-color: rgba(#cccccc, 0.2);
  }
}

.answerItem {
  padding: 1rem;

  &__question {
    margin-bottom: .5rem;

    b {
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}
</style>