import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyCZMt51vhedQEryYLndGm6nWzY2kwOpGX0",
  authDomain: "hechos-29.firebaseapp.com",
  databaseURL: "https://hechos-29.firebaseio.com",
  projectId: "hechos-29",
  storageBucket: "hechos-29.appspot.com",
  messagingSenderId: "268769128206",
  appId: "1:268769128206:web:12208eb5fc95e4e49a315f",
  measurementId: "G-9K7P0L704X"
};
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
const formsCollection = db.collection('forms')
const settingsCollection = db.collection('settings')

// export utils/refs
export {
  db,
  auth,
  storage,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
  formsCollection,
  settingsCollection
}