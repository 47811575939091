<template>
  <header>
    <section>
      <div class="col1">
        <router-link to="/">
          <h3>Devocional R3:18</h3>
        </router-link>
        <ul class="inline">
          <li>
            <router-link to="/">Inicio</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/admin">Admin</router-link>
          </li>
          <li>
            <router-link to="/settings">Ajustes</router-link>
          </li>
          <li><a @click="logout()">Salir</a></li>
        </ul>
      </div>
    </section>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'isAdmin'
    ])
  }
}
</script>
