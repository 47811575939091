<template>
  <div>
    <h3>{{ formTitle }}</h3>
    <div class="field-item">
      <label for="form-title">Título</label>
      <input id="form-title" type="text" v-model="title">
    </div>
    <div class="field-item">
      <label for="form-description">Descripción (Opcional)</label>
      <textarea id="form-description" type="text" v-model="description"></textarea>
    </div>
    <div class="field-item">
      <label for="form-day">Día</label>
      <input id="form-day" type="number" v-model="day">
    </div>
    <div class="field-item">
      <label for="form-week">Semana</label>
      <input id="form-week" type="number" v-model="week">
    </div>
    <div class="field-item">
      <label for="form-date">Fecha</label>
      <input id="form-date" type="date" v-model="date">
    </div>
    <div class="field-item">
      <label for="form-verse">Versículo</label>
      <input id="form-verse" type="text" v-model="verse">
    </div>
    <div class="field-item">
      <label for="form-video">Video</label>
      <input id="form-video" type="text" v-model="video">
    </div>
    <div class="field-item">
      <label>Agregar Preguntas</label>

      <div class="form-item__add-question">
        <input id="form-questions" type="text" v-model="questionText">
        <select name="question-type" id="form-question-type" v-model="questionType">
          <option value="text">Texto</option>
          <option value="textarea">Texto Largo</option>
          <option value="message">Mensaje</option>
        </select>
        <button @click="addQuestion">Agregar</button>
      </div>

      <div v-if="questions.length > 0" class="form-item__question-preview">
        <h5>Preguntas</h5>
        <div class="question-item" :key="index" v-for="(question, index) in questions">
          <input type="text" :name="question.name" v-on:input="updateQuestion($event, index)" :value="question.question">
          <select name="question-type" id="form-question-type" v-model="question.type">
            <option value="text">Texto</option>
            <option value="textarea">Texto Largo</option>
            <option value="message">Mensaje</option>
          </select>
          <div class="removeQuestion" @click="deleteQuestion(index)">x</div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__actions">
          <button v-if="$route.params.id" class="button button__error" @click="deleteConfirm">Borrar Devocional</button>
          <button v-if="$route.params.id" class="button" @click="updateForm">Actualizar</button>
          <button v-else class="button" @click="createForm">Publicar Devocional</button>
        </div>
      </div>

      <template>
        <div class="modal" v-if="showDeleteConfirm">
          <div class="modal-content">
            <div @click="closeModal" class="close">cerrar</div>
            <h3>Borrar Devocional</h3>
            <div>
              <p>Quieres borrar el devocional <b>{{ title }}</b>?</p>
              <button @click="deleteForm" class="button button__error">Borrar</button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      title: '',
      description: '',
      day: null,
      week: null,
      date: new Date(),
      published: true,
      video: '',
      verse: '',
      questions: [],
      questionText: '',
      questionType: null,
      showDeleteConfirm: false
    }
  },
  computed: {
    ...mapState(['forms']),
    formTitle() {
      return this.$route.params.id ? 'Actualizar Devocional' : 'Nuevo  Devocional'
    }
  },
  methods: {
    addQuestion() {
      this.questions.push({
        question: this.questionText,
        type: this.questionType,
        name: `p${this.questions.length + 1}`
      })

      this.questionText = ''
      this.questionType = null
    },
    createForm() {
      this.$store.dispatch('createForm', {
        title: this.title,
        description: this.description,
        day: this.day,
        week: this.week,
        date: this.date,
        published: true,
        video: this.video,
        verse: this.verse,
        questions: JSON.stringify(this.questions)
      }).then(() => {
        this.$notify({
          group: 'r318',
          title: 'Devocional creado con éxito',
          type: 'success',
          position: ['bottom', 'right']
        });
        this.$router.push('/admin/devocionales')
      }).catch(e => {
        console.log('CREATE FORM ERROR', e)
        this.$notify({
          group: 'r318',
          title: 'Ocurrió un error al crear el devocional. Por favor intenta de nuevo.',
          type: 'error',
          position: ['bottom', 'right']
        });
      })
    },
    updateForm() {
      this.$store.dispatch('updateForm', {
        id: this.$route.params.id,
        title: this.title,
        description: this.description,
        day: this.day,
        week: this.week,
        date: this.date,
        published: true,
        video: this.video,
        verse: this.verse,
        questions: JSON.stringify(this.questions)
      }).then(() => {
        this.$notify({
          group: 'r318',
          title: 'Devocional actualizado con éxito',
          type: 'success',
          position: ['bottom', 'right']
        });
        this.$router.push('/admin/devocionales')
      }).catch(e => {
        console.log('UPDATE FORM ERROR', e)
        this.$notify({
          group: 'r318',
          title: 'Ocurrió un error al actualizar el devocional. Por favor intenta de nuevo.',
          type: 'error',
          position: ['bottom', 'right']
        });
      })
    },
    closeModal() {
      this.showDeleteConfirm = false
    },
    deleteConfirm() {
      this.showDeleteConfirm = true
    },
    deleteForm() {
      this.$store.dispatch('deleteForm', this.$route.params.id).then(() => {
        this.$notify({
          group: 'r318',
          title: 'Devocional borrado con éxito',
          type: 'success',
          position: ['bottom', 'right']
        });
        this.$router.push('/admin/devocionales')
      }).catch(e => {
        console.log('DELETE FORM ERROR', e)
        this.$notify({
          group: 'r318',
          title: 'Ocurrió un error al borrar el devocional. Por favor intenta de nuevo.',
          type: 'error',
          position: ['bottom', 'right']
        });
      })
    },
    deleteQuestion(i) {
      this.questions.splice(i, 1)
    },
    updateQuestion(e, i) {
      this.questions[i].question = e.target.value
    },
    clearState() {
      this.title = '',
      this.description = '',
      this.day = null,
      this.week = null,
      this.date = new Date(),
      this.published = true,
      this.video = '',
      this.verse = '',
      this.questions = [],
      this.questionText = '',
      this.questionType = null
    },
    updateFormFields(currentForm) {
      if (currentForm && currentForm.title) {
        this.title = currentForm.title
      }

      if (currentForm && currentForm.description) {
        this.description = currentForm.description
      }

      if (currentForm && currentForm.day) {
        this.day = currentForm.day
      }

      if (currentForm && currentForm.week) {
        this.week = currentForm.week
      }

      if (currentForm && currentForm.date) {
        this.date = currentForm.date
      }

      if (currentForm && currentForm.verse) {
        this.verse = currentForm.verse
      }

      if (currentForm && currentForm.video) {
        this.video = currentForm.video
      }

      if (currentForm && currentForm.questions) {
        this.questions = JSON.parse(currentForm.questions)
      }
    }
  },
  mounted() {
    const formId = this.$route.params.id

    if (this.forms && this.forms.length > 0 ) {
      const currentForm = this.forms.find(form => form.id === formId)

      this.updateFormFields(currentForm)

      this.$store.watch(
        state => state.forms
        ,
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            this.updateFormFields(newValue)
          }
        }
      )
    }
  },
  beforeDestroy() {
    this.clearState()
  },
  watch: {
    $route (to, from){
      if (to !== from) {
        this.clearState()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item {
  &__add-question {
    background-color: #f2f2f2;
    display: flex;
    padding: 2rem;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    input {
      margin-bottom: 0;
    }

    select {
      flex: 0 0 20%;
      margin: 0 1rem;
    }
  }

  &__question-preview {
    border: 1px solid #f2f2f2;
    margin-bottom: 1rem;
    padding: 2rem;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}

.question-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  input {
    margin-bottom: 0;
  }

  select {
    flex: 0 0 20%;
    margin: 0 1rem;
  }
}

.removeQuestion {
  cursor: pointer;
}
</style>