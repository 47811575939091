<template>
  <div>
    <h1>Devocionales</h1>
    <div class="devotionalItem" @click="() => $router.push(`/admin/form/${form.id}`)" :key="form.id" v-for="form in forms">
      <div class="devotionalItem__title">{{ form.title }}</div>
      <div class="devotionalItem__day">Día {{ form.day }}</div>
      <div class="devotionalItem__week">Semana {{ form.week }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['forms'])
  }
}
</script>

<style lang="scss" scoped>
.devotionalItem {
  background-color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid rgba(#cccccc, 0.3);
  display: flex;
  justify-content: space-between;

  &__title {
    margin-right: auto;
  }

  &__day {
    padding: 0 1rem;
  }

  &:hover {
    background-color: rgba(#cccccc, 0.2);
  }
}
</style>