<template>
  <div class="weekList">
    <select @change="weekChange">
      <option :value="key" :key="key" v-for="(week, key) in weeks" class="weekItem" :selected="key == activeWeek">Semana {{ key }}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['weeks', 'activeWeek'])
  },
  methods: {
    weekChange(e) {
      this.$store.dispatch('setActiveWeek', e.target.value)
    }
  }
}
</script>

<style>
.weekList {
  display: flex;
  justify-content: flex-end;
}
.weekItem {
  cursor: pointer;
  background-color: #ffffff;
  padding: 2em;
  border-bottom: 1px solid rgba(#cccccc, 0.3);
}
</style>