<template>
  <div>
    <h1>Semana Activa</h1>
    <input type="number" @input="updateActiveWeek($event.target.value)" :value="settings.activeWeek" v-if="settings && settings.activeWeek" />
  </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
  computed: {
    ...mapState(['settings'])
  },
  methods: {
    updateActiveWeek(n) {
      this.$store.dispatch('updateSettingsActiveWeek', n).then(() => {
        this.$notify({
          group: 'r318',
          title: 'Semana activa actualizada con éxito',
          type: 'success',
          position: ['bottom', 'right']
        });
      }).catch(e => {
        console.log('UPDATE ACTIVE WEEK ERROR', e)
        this.$notify({
          group: 'r318',
          title: 'Ocurrió un error al actualizar la semana activa. Por favor intenta de nuevo.',
          type: 'error',
          position: ['bottom', 'right']
        });
      })
    }
  }
}
</script>

<style>

</style>