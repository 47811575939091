<template>
  <div>
      <h2>
          Reporte
      </h2>
       <form @submit.prevent v-if="showForm">
          <div>
            <label for="name">Buscar</label>
            <input v-model.trim="search.keyword" type="text" placeholder="Buscar..." id="keyword" required="true"/>
          </div>
         <button @click="loadItems()" class="button">Buscar</button>
          <div class="extras">
            <a @click="resetForm()">Cancelar</a>
          </div>
        </form>
      <table v-if="items" class="font-weight-light mb-2">
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Fecha</th>
          <th scope="col">Nombre</th>
          <th scope="col">Devocional</th>
        </tr>
          <tr :key="user.id" v-for="user in reportItems">
            <td class="devotionalItem__title">{{ user.id }}             
            </td>
            <td class="devotionalItem__title">{{ user.Date }}             
            </td>
            <td class="devotionalItem__title">{{ user.Name }}             
            </td>
            <td class="devotionalItem__title">{{ user.Form }}             
            </td>
          </tr>
      </table>       
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['reportItems'])
  },
  data () {
    return {
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Fecha', value: 'Date' },
            { text: 'E-mail', value: 'Name' },
            { text: 'Devocional', value: 'Form' }
        ],
        search: {keyword:''},
        showForm:true,
        items: null,
        dialog: false, // used to toggle the dialog
        editedItem: {} // empty holder for create/update ops
    }
  },
  mounted() {
    //this.loadItems()
  },
  methods: {
    resetForm() {
      this.search.keyword = '';
      this.items = null;
    },
    loadItems() {
        this.$store.dispatch('loadItemsReport', this.search.keyword).then(() => {
          this.items = this.$store.state.reportItems
        console.log(this.items);
      }).catch(e => {
        if (e) {
          console.log(e);    
          this.$notify({
            group: 'r318',
            title: 'Ha ocurrido un error',
            text: 'Por favor revisa los datos e itenta de nuevo.',
            type: 'error',
          });          
        }
      })
    }
}
}
</script>
