import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'
import Dashboard from '../views/Dashboard.vue'
import Forms from '../components/Forms'
import WeekVerseUpload from '../components/WeekVerseUpload'
import Devocionales from '../components/Devocionales'
import SelectActiveWeek from '../components/SelectActiveWeek'
import Users from '../components/Users'
import AnswersByUser from '../components/AnswersByUser'
import Report from '../components/Report'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import( /* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devocional/:id',
    name: 'Devocional',
    component: () => import( /* webpackChunkName: "devocional" */ '../views/Devocional.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin.vue'),
    children: [
      { path: 'publicar-versiculo', component: WeekVerseUpload},
      { path: 'devocionales', component: Devocionales},
      { path: 'publicar', component: Forms},
      { path: 'form/:id', component: Forms},
      { path: 'semana-activa', component: SelectActiveWeek},
      { path: 'usuarios', component: Users},
      { path: 'answers/:id', component: AnswersByUser},
      { path: 'reporte', component: Report}
    ],
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router