<template>
  <div id="app">
    <h3>Cargar Versículo</h3>
    <vue-dropzone
      ref="imgDropZone"
      id="customdropzone"
      :options="dropzoneOptions"
      @vdropzone-complete="afterComplete"
    ></vue-dropzone>
    <div v-if="image" class="image-div">
      <img :src="image" class="image" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as fb from '../firebase'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
let uuid = require('uuid')

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastra una imagen o haz click aqui.</p>
          <p class="form-text">Tipo de imagen aceptado: .jpg, .jpeg, .png</p>
          `
      },
      image: ''
    };
  },
  computed: {
    ...mapState(['settings'])
  },
  methods: {
    async afterComplete(upload) {
      const imageName = uuid.v1();
      this.isLoading = true;
      try {
        //save image
        let file = upload;
        const metadata = {
          contentType: "image/png"
        };
        const storageRef = fb.storage.ref();
        const imageRef = storageRef.child(`images/${imageName}.png`);
        await imageRef.put(file, metadata);
        const downloadURL = await imageRef.getDownloadURL();
        this.image = downloadURL
        this.setVerseUrl(downloadURL)
      } catch (error) {
        console.log('IMAGE UPLOAD', error);
      }
      this.$refs.imgDropZone.removeFile(upload);
    },
    setVerseUrl(url) {
      this.$store.dispatch('updateSettingsVerseUrl', url).then(() => {
          this.$notify({
            group: 'r318',
            title: 'Versículo Actualizado',
            type: 'success',
            position: ['bottom', 'right']
          });
        }).catch(e => {
          console.log('UPDATE VERSE ERROR', e)
          this.$notify({
            group: 'r318',
            title: 'Ocurrió un error al actualizar el versículo. Por favor intenta de nuevo.',
            type: 'error',
            position: ['bottom', 'right']
          });
        })
    },
  },
  mounted() {
    if (this.settings && this.settings.verseUrl) {
      this.image = this.settings.verseUrl
    }
  }
};
</script>

<style>
.image-div {
  display: flex;
  margin: 25px;
}
.image {
  max-width: 250px;
  margin: 15px;
}
</style>
