<template>
  <div class="weekVerse">
    <h4>Versículo de la Semana</h4>
    <img v-if="settings && settings.verseUrl" :src="settings.verseUrl" alt="Versículo de la semana">
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings'])
  }
}
</script>

<style lang="scss" scoped>
.weekVerse {
  padding-top: 3rem;
  @media (min-width: 780px) {
    position: sticky;
    top: 0;
  }
}

.weekVerse img {
  max-width: 100%;
}
</style>