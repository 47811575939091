<template>
  <div id="week">
    <section class="weekSection">
      <div class="col1">
        <WeekVerse />
      </div>
      <div class="formsList col2">
        <WeekList />
        <div class="intro">
          <h1 v-if="userProfile && userProfile.name">¡Hola {{ userProfile.name }}! </h1>
          <p>1. Busca un lugar donde puedas estar solo o en compañía de alguno de tus padres. Este lugar debe ser tranquilo, sin ruidos ni distracciones. <br>
          2. Toma 5 min para agradecerle a Dios por quién es Él y por todo lo que ha hecho contigo y en tu familia. <br>
          3. Lee el pasaje Bíblico correspondiente al día. <br>
          4. Responde las Preguntas correspondientes al día. <br>
          5. Termina este tiempo poniendo en manos de Dios todas tus peticiones y pidiéndole que te ayude a poner en practica lo que has leído.</p>
        </div>
        <div class="weekHeading">
          <h3>Semana {{ activeWeek }}</h3>
        </div>
        <div class="formsListWrapper" v-if="activeForms">
          <div v-for="form in activeForms" :key="form.id" @click="openForm(form.id)" class="formsListItem">
            <h5>{{ form.title }}</h5>
          </div>
        </div>
        <div v-else>
          <p class="no-results"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import WeekVerse from '@/components/WeekVerse'
import WeekList from '@/components/WeekList.vue'

export default {
  components: {
    WeekVerse,
    WeekList
  },
  computed: {
    ...mapState(['userProfile', 'forms']),
    ...mapGetters(['activeWeek', 'activeForms'])
  },
  methods: {
    openForm(id) {
      this.$router.push({ path: `/devocional/${id}` })
    }
  },
  filters: {
    formatDate(val) {
      if (!val) { return '-' }

      let date = val.toDate()
      return moment(date).fromNow()
    },
    trimLength(val) {
      if (val.length < 200) { return val }
      return `${val.substring(0, 200)}...`
    }
  },
  mounted() {
    this.$store.dispatch('fetchForms')
  }
}
</script>

<style lang="scss" scoped>
.weekSection {
  @media (min-width: 768px) {
    padding-top: 4rem;
  }
}

.formsList {
  @media (min-width: 820px) {
    flex: 0 0 800px;
  }
}

.formsListItem {
  background-color: #ffffff;
  cursor: pointer;
  padding: 2rem;
  border-bottom: 1px solid rgba(#cccccc, 0.3);

  &:hover {
    background-color: rgba(#cccccc, 0.2);
  }
}

.intro {
  margin-bottom: 2rem;
}
</style>
