<template>
  <div>
    <h1>Usuarios</h1>
    <div class="devotionalItem" :key="user.id" v-for="user in users">
      <div class="devotionalItem__title">{{ user.name }} {{ user.lastName }} id: {{ user.id }}
        <i v-if="user.isAdmin" style='color:gray' title='Administrador'>Admin</i>
      </div>
      <button @click="() => $router.push(`/admin/answers/${user.id}`)" class="button devotionalItem__day">Respuestas</button>
      <button class="button" @click="deleteU(user.id)">Eliminar</button>
      <!-- <button class="button">Ver</button> -->
    </div>
    <form v-if="showLoginForm" @submit.prevent>
          <h1>Registrar Usuario</h1>
          <div>
            <label for="name">Nombre</label>
            <input v-model.trim="signupForm.name" type="text" placeholder="Nombre" id="name" required="true"/>
          </div>
          <div>
            <label for="title">Apellido</label>
            <input v-model.trim="signupForm.lastName" type="text" placeholder="Apellido" id="lastName"  required="true"/>
          </div>
          <div>
            <label for="email2">Email</label>
            <input v-model.trim="signupForm.email" type="text" placeholder="tu@email.com" id="email2"  required="true"/>
          </div>
          <div>
            <label for="password2">Contraseña</label>
            <input v-model.trim="signupForm.password" type="password" placeholder="min 6 caracteres" id="password2"  required="true"/>
          </div>
          <div>
            <label for="isAdmin">¿Es Administrador?</label>
            <input v-model.trim="signupForm.isAdmin" type="checkbox" id="isAdmin" />
          </div>
          <button @click="signup()" class="button">Aceptar</button>
          <div class="extras">
            <a @click="toggleForm()">Cancelar</a>
          </div>
        </form>
    <div class="extras">
      <a @click="toggleForm()">Crear cuenta</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['users'])
  },
  data() {
    return {
      signupForm: {
        name: '',
        lastName: '',
        email: '',
        password: '',
        isAdmin: false
      },
      showLoginForm: false
    }
},
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    signup() {
      if (this.signupForm.email=='' || this.signupForm.name=='' || this.signupForm.lastName=='' || this.signupForm.password==''){
         return;
      }
      this.$store.dispatch('createUser', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        name: this.signupForm.name,
        lastName: this.signupForm.lastName,
        isAdmin: this.signupForm.isAdmin
      }).then(() => {
        this.$notify({
          group: 'r318',
          title: 'Usuario creado con éxito',
          type: 'success',
          position: ['bottom', 'right']
        });
        this.toggleForm();
      }).catch(e => {
        if (e) {
          if (e && e.code === 'auth/email-already-in-use') {
            this.$notify({
              group: 'r318',
              title: 'Cuenta existente',
              text: 'El correo ingresado ya existe',
              type: 'error',
            });
          } else if (e && e.code === 'auth/weak-password') {
            this.$notify({
              group: 'r318',
              title: 'Contraseña invalida',
              text: 'Ingresa mínimo 6 caracteres para la contraseña.',
              type: 'warn',
            });
          } else {
            this.$notify({
              group: 'r318',
              title: 'Ha ocurrido un error',
              text: 'Por favor revisa los datos e itenta de nuevo.',
              type: 'error',
            });
          }
        }
      })
    }, deleteU(uid) {
      console.log(uid);
      if (!confirm('¿Seguro que desea eliminar el usuario?')){
         return;
      }
      this.$store.dispatch('deleteUser', uid).then(() => {
        this.$notify({
          group: 'r318',
          title: 'Usuario eliminado con éxito',
          type: 'success',
          position: ['bottom', 'right']
        });
        this.toggleForm();
      }).catch(e => {
        if (e) {
          console.log(e);    
          this.$notify({
            group: 'r318',
            title: 'Ha ocurrido un error',
            text: 'Por favor revisa los datos e itenta de nuevo.',
            type: 'error',
          });          
        }
      })
    }
  }}
</script>

<style lang="scss" scoped>
.devotionalItem {
  background-color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid rgba(#cccccc, 0.3);
  display: flex;
  justify-content: space-between;

  &__title {
    margin-right: auto;
  }

  &__day {
    padding: 0 1rem;
  }

  &:hover {
    background-color: rgba(#cccccc, 0.2);
  }
}

.button {
  min-width: 0;
  margin: 0 3px;
}
</style>