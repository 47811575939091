<template>
  <div id="app">
    <SiteNav v-if="showNav"></SiteNav>
    <router-view/>
    <notifications group="r318" position="bottom right" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SiteNav from '@/components/SiteNav'

export default {
  components: {
    SiteNav
  },
  computed: {
    ...mapState(['userProfile']),
    showNav() {
      return Object.keys(this.userProfile).length > 1
    }
  },
  // created() {
  //   this.$store.dispatch('getSettings')
  // }
}
</script>
